<template>
  <div  class=" ma-md-5 mb-16 px-0 px-sm-16" >
    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/myorder" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">订单明细</div>
    </div>

    <v-card class="d-flex item-header pa-4 justify-space-between" flat tile>
      <div class="">订单编号:  {{info.order_no}}</div>
      
    </v-card>
    <v-card class="pa-4" flat tile>
      <v-row v-for="item in info.order_goods" :key="item.sku_id">
        <v-col class="" cols="3">
          <v-img :src="imgdomain + item.picture_info.pic_cover_small" max-height="200" max-width="200"></v-img>
        </v-col>
        <v-col class="d-flex flex-column pt-4" cols="9" >
          <div class="text-h6  ">{{item.brand_name}} </div>
          <div class="text-h6 font-weight-bold ">{{item.goods_name}}</div>
          <div class="text-h6  ">{{item.sku_name}} </div>
          <div class="text-h6  ">{{item.price}}  x{{item.num}}</div>
          
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-md-4" flat tile>
      <v-row>
        <v-col class="px-md-12" cols="12" md="10">
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>下单时间: </div>
            <div>{{info.create_time_str}}</div>
          </div>
          <div class="text-subtitle-1  d-flex justify-space-between ma-2">
            <div>支付时间: </div>
            <div>{{info.pay_time_str?info.pay_time_str:'-'}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>订单状态： </div>
            <div>{{info.status_name}} <span v-if="info.order_status==-1">({{info.order_goods[0].status_name}})</span> </div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2" v-if="info.order_status==2 && info.order_goods[0].express_info">
            <div>物流信息： </div>
            <div>{{info.order_goods[0].express_info.express_company}} {{info.order_goods[0].express_info.express_no}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2" v-if="info.order_status==-1 && info.order_goods[0] && info.order_goods[0].refund_status==3">
            <div>退货物流： </div>
            <div>{{info.order_goods[0].refund_shipping_company}} {{info.order_goods[0].refund_shipping_code}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>优惠券： </div>
            <div>{{info.coupon_money}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>实付金额： </div>
            <div>{{info.pay_money}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>支付方式： </div>
            <div>{{info.payment_type_name}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>收货地址： </div>
            <div>{{info.address}}</div>
          </div>
          <div class="text-subtitle-1 d-flex justify-space-between ma-2">
            <div>发票： </div>
            <div v-if="info.buyer_invoice!=''">{{info.buyer_invoice_info.name}},{{info.buyer_invoice_info.address}}</div>
            <div v-if="info.buyer_invoice==''">-</div>
          </div>
          
        </v-col>
      </v-row>
      <v-row>
        <v-col class="" cols="12" md="3" v-if="[1,2,3].indexOf(info.order_status)>-1">
          <v-btn  dark outlined  tile class="main-btn" @click="refund">申请售后</v-btn>
        </v-col>
        <v-col class="" cols="12" md="3" v-if="info.pay_status==0 && info.order_status==0">
          <v-btn  dark outlined  tile class="main-btn" @click="pay">立即支付</v-btn>
        </v-col>
        <v-col class="" cols="12" md="3" v-if="info.order_status==-1 && info.order_goods[0] && info.order_goods[0].refund_status==2">
          <v-btn  dark outlined  tile class="main-btn" @click="express_dialog=true">填写物流单号</v-btn>
        </v-col>
      </v-row>
    </v-card>

    

    <v-dialog
      v-model="dialog"
      max-width="300px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          <v-img
                src="../assets/WePayLogo.png"
                height="40"
                max-width="148"
              >
              </v-img>
          
        </v-card-title>
        <v-card-text>
          <v-img :src="weixincode" height="252" width="252" >
          </v-img>
          
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="refund_dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          申请售后
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="退款金额*"
                  v-model="refundmoney"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  label="事由说明*"
                  required
                  v-model="refundreason"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="refund_dialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="goRefund"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="express_dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-center justify-center">
          填写退货物流
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="物流公司名称*"
                  v-model="companyname"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="单号*"
                  v-model="expressno"
                  required
                ></v-text-field>
                
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="express_dialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="goExpress"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>


import { mapState } from 'vuex'
import orderApi from '@/api/order'
import {payWakeUp} from '@/utils/pay';

export default {


  data: () => ({
    weixin_params: null,
    out_trade_no:'',
    express_dialog:false,
    refund_dialog:false,
    dialog : false,
    itemid : 0,
    weixincode:"",
    info : {buyer_invoice_info:{}},
    refundmoney:0,
    refundreason : "",
    companyname : "",
    expressno : ""
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
      apidomain: state => state.app.apidomain,
      imgdomain: state => state.app.imgdomain,
    })
    
  },
  created() {
    this.init()
    
  },
  methods: {
    init() {
      let out_trade_no = this.$route.query.out_trade_no
      let paying = this.$route.query.paying
      

      let params = {out_trade_no:out_trade_no}
      orderApi.orderinfo(params).then(res => {
        this.info = res.data.order
        if (paying=="1") {
          this.pay()
        }
        
      })
    },
    paynow() {

    },
    goExpress() {
      if (this.companyname=="") {
        this.$message.warning("请输入物流公司名称")
        return
      }
      if (this.expressno=="") {
        this.$message.warning("请输入物流单号")
        return
      }
      let params = {order_id:this.info.order_id,refund_express_company:this.companyname,refund_shipping_no:this.expressno}
      orderApi.orderrefundexpress(params).then(res => {
        if (res.data==1) {
          this.$message.success("物流信息提交成功！请等待管理员处理。")
          this.express_dialog = false
          this.init()
        }
      }) 
    },
    goRefund() {
      this.refundmoney = parseFloat(this.refundmoney)
      if (this.refundmoney<=0) {
        this.$message.warning("请输入退款金额")
        return
      }
      if (this.refundreason=="") {
        this.$message.warning("请输入事由说明")
        return
      }
      let params = {order_id:this.info.order_id,refund_require_money:this.refundmoney,refund_reason:this.refundreason}
      orderApi.orderrefund(params).then(res => {
        if (res.data==1) {
          this.$message.success("申请售后成功！请等待管理员审核。")
          this.refund_dialog = false
          this.init()
        }
      }) 
    },
    
    refund() {
      this.refund_dialog = true;
    },
    pay() {
      let paytype = this.info.payment_type==2?"NsAlipay":"NsWeixinpay"
      let pays = {type:paytype,out_trade_no:this.info.out_trade_no}

      if (this.$util.inWeiXin()) {

        let weixin_code = this.$route.query.code
        if (weixin_code) {
          pays.code = weixin_code
          //this.pay2(pays)
          payWakeUp({payparams:pays,qrcodeCallBack:(code_url) => {
            this.dialog = true
            this.weixincode = this.apidomain + "qrcode.php?code=" + encodeURIComponent(code_url)
          }})
        } else {
          const token = this.$util.getToken()
          location.href = this.apidomain + "api.php?action=onlinepay&token="+token+"&type="+paytype+"&out_trade_no="+this.info.out_trade_no
          return
        }
        
      }
      else {
        payWakeUp({payparams:pays,qrcodeCallBack:(code_url) => {
          this.dialog = true
          this.weixincode = this.apidomain + "qrcode.php?code=" + encodeURIComponent(code_url)
        }})
        //this.pay2(pays)
      }
      
      
    },
    pay2(params){
      orderApi.onlinepay(params).then(response => {
        if (response.data.code==1) {
          let type=response.data.data.return_type
          if (type=="html") {
            if (this.$vuetify.breakpoint.mobile) {
              document.write("<html><title>VL.WANG 订单支付</title><body>"+response.data.data.html+"</body></html>")
            } else {
              let newwindow = window.open();
              newwindow.document.write('<html><title>VL.WANG 订单支付</title>');
              newwindow.document.write('<body>');
              newwindow.document.write(response.data.data.html);
              newwindow.document.write('</body></html>');
              this.checkPayStatus()
            }
            
          } else if (type=="qrcode"){
            //
            this.dialog = true
            this.weixincode = this.domain + "qrcode.php?code=" + encodeURIComponent(response.data.data.code_url)
            //this.checkPayTimer = setTimeout(this.checkPayStatus,700)
            this.checkPayStatus()
          } else if (type=="url"){
            //
            location.href = response.data.data.url
          } else if (type=="weixin") {
            this.weixin_params = response.data.data.params
            if(typeof WeixinJSBridge == "undefined"){
              if(document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false);
              }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', this.jsApiCall);
                document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall);
              }
            
            } else {
              this.jsApiCall();
            }
          } else if (type=="warning") {
            console.log(response.data.data.code_url)
          } else if (type=="payed") {
            console.log(response.data.data.code_url)
            this.$message.warning("该订单已支付")
          }
        }
        else {
          this.$message.warning("系统响应异常，请联系管理员")
        }
      })
    },
    jsApiCall() {
      let params = this.weixin_params
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', params,
        function(res){
          window.WeixinJSBridge.log(res.err_msg);
          
          if(res.err_msg == "get_brand_wcpay_request:ok" ) {
            console.log(res.err_msg)
            this.$router.push("/payreturn?result=success&out_trade_no="+this.out_trade_no)
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.$router.push("/payreturn?result=failed&out_trade_no="+this.out_trade_no)
             console.log(res.err_msg)
          }else{
            this.$router.push("/payreturn?result=exception&msg="+res.err_msg+"&out_trade_no="+this.out_trade_no)
             console.log(4)
          }
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg 将在用户支付成功后返回 ok，但并不保证它绝对可靠。
        }
      );
    },
    checkPayStatus() {
      if (this.info.out_trade_no!="") {
        orderApi.paystatus({out_trade_no:this.info.out_trade_no}).then(res => {
           let status = res.code
           if (status==0) {
              this.$router.push("/payreturn?result=success&out_trade_no="+this.out_trade_no)
           } else {
             setTimeout(this.checkPayStatus,1000)
           }
        })
      }
    },
    
  },
};
</script>
<style scoped>

.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}
</style>

