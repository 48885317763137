import orderApi from '@/api/order'
import Vue from 'vue'

let weixin_params = null
let out_trade_no = ""
export const payWakeUp = (params) => {
    weixin_params = null
    out_trade_no = params.payparams.out_trade_no
    orderApi.onlinepay(params.payparams).then(response => {
      if (response.data.code==1) {
        let type=response.data.data.return_type
        if (type=="html") {
        
          if (Vue.prototype.$util.isMobile()) {
            document.write("<html><title>VL.WANG 订单支付</title><body>"+response.data.data.html+"</body></html>")
          } else {
            let newwindow = window.open();
            newwindow.document.write('<html><title>VL.WANG 订单支付</title>');
            newwindow.document.write('<body>');
            newwindow.document.write(response.data.data.html);
            newwindow.document.write('</body></html>');
            this.checkPayStatus()
          }
          
        } else if (type=="qrcode"){
          
          params.qrcodeCallBack(response.data.data.code_url)
          
          checkPayStatus()
        } else if (type=="url"){
          //
          location.href = response.data.data.url
        } else if (type=="weixin") {
          weixin_params = response.data.data.params
          if(typeof WeixinJSBridge == "undefined"){
            if(document.addEventListener ){
              document.addEventListener('WeixinJSBridgeReady', jsApiCall, false);
            }else if (document.attachEvent){
              document.attachEvent('WeixinJSBridgeReady', jsApiCall);
              document.attachEvent('onWeixinJSBridgeReady', jsApiCall);
            }
          
          } else {
            jsApiCall();
          }
        } else if (type=="warning") {
          console.log(response.data.data.code_url)
        } else if (type=="payed") {
          console.log(response.data.data.code_url)
          
          Vue.prototype.$message.warning('该订单已支付')
        } 
      }
      else {
        Vue.prototype.$message.warning("系统响应异常，请联系管理员")
      }
    })
}

const jsApiCall = () => {
  
    let params = weixin_params
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', params,
        function(res){
        window.WeixinJSBridge.log(res.err_msg);
        
        if(res.err_msg == "get_brand_wcpay_request:ok" ) {
            console.log(res.err_msg)
            Vue.router.push("/payreturn?result=success&out_trade_no="+this.out_trade_no)
        }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            Vue.router.push("/payreturn?result=failed&out_trade_no="+this.out_trade_no)
            console.log(res.err_msg)
        }else{
            Vue.router.push("/payreturn?result=exception&msg="+res.err_msg+"&out_trade_no="+this.out_trade_no)
            console.log(4)
        }
        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg 将在用户支付成功后返回 ok，但并不保证它绝对可靠。
        }
    );
  
}
const checkPayStatus = () => {

    if (out_trade_no!="") {
      orderApi.paystatus({out_trade_no:out_trade_no}).then(res => {
         let status = res.code
         if (status==0) {
            Vue.router.push("/payreturn?result=success&out_trade_no="+out_trade_no)
         } else {
           setTimeout(checkPayStatus,2000)
         }
      })
    }
  }